import React from "react";
import MultiSelectInput from "components/MainApp/atoms/Forms/MultiSelectInput";
import Toggle from "components/MainApp/atoms/Forms/Toggle";

import "./styles.scss";

const DeviceSelector = ({
  devices,
  selected,
  handleToggleChange,
  disableToggle,
  checked,
  handleChange,
  handleOpen,
  handleClose,
  maxDevicesSelected,
}) => {
  return (
    <div className="device-selector-root">
      <div className="input-wrapper">
        <MultiSelectInput
          items={devices}
          selected={selected}
          label={"Dispositivos"}
          required={false}
          handleChange={handleChange}
          handleClose={handleClose}
          handleOpen={handleOpen}
          maxDevicesSelected={maxDevicesSelected}
        ></MultiSelectInput>
      </div>
      <div className="toggle-wrapper">
        <p>
          Lectura <span>{checked ? "habilitada" : "deshabilitada"}</span>
        </p>
        <Toggle
          handleChange={handleToggleChange}
          checked={checked}
          disabled={disableToggle}
          name="channel"
        />
      </div>
    </div>
  );
};

export default DeviceSelector;
