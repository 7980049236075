import style from "./EmptyArcTags.module.scss";
import emptyTags from "assets/images/empty-tags.svg";

export const EmptyArcTags = () => {
  return (
    <div className={style["empty-arc-tags"]}>
      <img src={emptyTags} alt="Lector de tags" />
      <p>No se han registrado tags, pase los productos a través del arco.</p>
    </div>
  );
};
