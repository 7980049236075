import React, { forwardRef, useImperativeHandle } from "react";
import { TagService } from "services/TagService";
import IntlMessageFormat from "intl-messageformat";


const SatoPrinter = forwardRef((props, ref) => {
    
    const sendCommand = (data) => {
        return new Promise((resolve, reject) => {
            TagService.configTagInfo(data.config_tag_id)
                .then((response) => {
                    var zplformat = response.zplFormat;
                    const messageFormatter = new IntlMessageFormat(zplformat, "es");

                    data.rfids.forEach(rfid => {
                        TagService.tag(rfid).then(tag => {
                            const command = messageFormatter.format(tag.variables);
                            sendMessage(command);
                        })
                    });
                    resolve()
                })
                .catch((error) => {
                    reject()
                });
        });
    };

    const sendMessage = (command) => {
        const extensionId = "ckadlmlggbdnngdlhehdifeanlaekilc";  
        window.postMessage({
          type: "PRINT_COMMAND",
          payload: { "command": command }
        });
    }

    // Exponemos la función al componente padre
    useImperativeHandle(ref, () => ({
        sendCommandToPrinter: sendCommand,
    }));

    return (
        <>a</>
    )

});


export default SatoPrinter;