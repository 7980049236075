import React from "react";
import i18n from "i18n";

import { ProductService } from "../../../../services/ProductService";
import { EventRepository } from "helpers/EventRepository";

import "./styles.scss";
import GenericFormContainer from "../../../../helpers/GenericFormContainer";
import AdminLayout from "../../../../components/MainApp/layouts/DesktopLayout";
import FormDisableProduct from "../../../../components/MainApp/organisms/Product/Details/FormDisableProduct";
import FormEditProduct from "../../../../components/MainApp/organisms/Product/Details/FormEditProduct";
import FormImageProduct from "../../../../components/MainApp/organisms/Product/Details/FormImageProduct";
import RoundedButton from "../../../../components/MainApp/atoms/RoundedButton";
import TwoOptionModal from "../../../../components/MainApp/atoms/Modals/TwoOptionModal";
import { isMobile } from "helpers/Mobile";

export class ProductDetailsView extends GenericFormContainer {
  headerOptions = [
    {
      icon: "icon-copy",
      name: "categories",
      handler: () => {
        alert("Copy functionality in development");
      },
      tooltip: "Categorías",
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,

      product: null,
      image_file: undefined, // null=eliminar, undefined=no seteada
      item_files: [],
      changed: false,
      modalOpen: false,
      modalInfo: {
        title: "",
        subtitle: "",
        closeLabel: "",
        confirmLabel: "",
      },
      selectedStarIndex: 0
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.product) {
      this.setState({
        product: { ...this.props.location.state.product },
        item_files: this.props.location.state.product.item_files,
        changed: true,
      });
      return;
    }

    const id = this.props.match.params.key;
    ProductService.productByNode(id).then(response => {
      const product = response.results;
      product["category"] =
        product["categories"].length > 0 ? product["categories"][0] : null;
      product["brand"] = product["brand"] ? product["brand"].name : null;

      this.setState({
        product: product,
        item_files: product.item_files,
      });
    });
  }

  getFileType = filename => {
    if (filename.includes(".png")) return "image/png";
    if (filename.includes(".jpg")) return "image/jpeg";
    if (filename.includes(".mp4")) return "video/mp4";
    if (filename.includes(".wav")) return "audio/wav";
    if (filename.includes(".ogg")) return "audio/ogg";
    return "";
  };

  handleSubmit = e => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("name", this.state.product.name);
    formData.append("description", this.state.product.description);
    if (this.state.product.category) {
      formData.append(
        "categories_id",
        this.state.product.category ? [this.state.product.category.id] : []
      );
    }
    formData.append("brand_name", this.state.product.brand);
    formData.append("purchase_price", this.state.product.purchase_price || 0);
    formData.append("sell_price", this.state.product.sell_price || 0);
    formData.append("enabled", this.state.product.enabled);

    formData.append("weight", this.state.product.weight);

    formData.append("measure_height", this.state.product.measure_height || 0);
    formData.append("measure_depth", this.state.product.measure_depth || 0);
    formData.append("measure_width", this.state.product.measure_width || 0);

    formData.append("image", "");

    if (this.state.item_files.length > 0) {
      let selectedFile = this.state.item_files[this.state.selectedStarIndex];
      const first_image_type = selectedFile instanceof File ? "file" : "url";

      let item_files_copy = [...this.state.item_files];
      let selected_file = item_files_copy.splice(
        this.state.selectedStarIndex,
        1
      )[0];
      item_files_copy.unshift(selected_file);

      item_files_copy.map((item_file, index) => {
        if (item_file instanceof File) {
          if (index === 0) formData.append("image", item_file);
          formData.append(`item_files[${index}].file_name`, item_file);
        } else if (item_file?.file_name) {
          formData.append(
            `item_files[${index}].file_name`,
            item_file?.file_name
          );
        }
      });
      formData.append("first_image_type", first_image_type);
    }

    this.beforeSubmit();
    ProductService.updateProductFormData(this.state.product.id, formData)
      .then(account => {
        this.afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("Tu producto se actualizó de manera exitosa."),
          type: "success"
        });
        this.props.history.push("/admin/products");
      })
      .catch(error => {
        this.afterSubmit();
        this.dealWithError(error, "Error actualizando el producto");
      });
  };

  handleToggle = e => {
    let product = { ...this.state.product };
    product.enabled = !product.enabled;
    this.setState({
      product: product,
      changed: true,
      modalOpen: false
    });
  };

  handleToggleModal = () => {
    let modalInfo;
    if (this.state.product.enabled) {
      modalInfo = this.disableProductModal;
    } else {
      modalInfo = this.enableProductModal;
    }
    this.setState({
      modalOpen: true,
      modalInfo: { ...modalInfo }
    });
  };

  handleChange = (e, files) => {
    let name = e.target.name;
    let product = { ...this.state.product };
    if (e.target.type !== "file") {
      product[name] = e.target.value;
    } else {
      product[name] = files[0];
    }
    this.setState({
      product: product,
      changed: true
    });
  };

  handleBlur = e => {
    const { errors } = this.state;
    let { name, value } = e.target;
    value = value.replace(/,/g, ".");
    if (isNaN(value) || value < 0)
      errors[name] = "El valor ingresado es inválido";
    else errors[name] = null;
    this.setState({
      errors: errors
    });
  };

  handleChangeImage = (e, newFiles, err) => {
    if (err) {
      this.setState({ errors: { image: err } });
      return;
    }
  const filesArray = Array.isArray(newFiles) ? newFiles : [];
  this.setState({
    image_file: filesArray.length > 0 ? filesArray[0] : null,
    item_files: [...this.state.item_files, ...filesArray],
    changed: true
  });
  };

  handleDeleteImage = e => {
    let item_files = e?.length ? Array.from(e) : [];
    this.setState({
      image_file: item_files ? item_files[0] : null,
      item_files: item_files,
      changed: true
    });
  };

  handleStarClick = idx => {
    this.setState({
      selectedStarIndex: idx,
      changed: true
    });
  };

  selectCategory() {
    this.props.history.push({
      pathname: "/admin/categories",
      state: {
        product: this.state.product
      }
    });
  }

  goBack = () => {
    this.props.history.push("/admin/products");
  };

  goBackModal = () => {
    if (!this.state.changed) this.goBack();

    let modalInfo = this.saveProductModal;
    this.setState({
      modalOpen: true,
      modalInfo: { ...modalInfo }
    });
  };

  //Modals Info
  disableProductModal = {
    title: "¿Quieres inhabilitar el producto?",
    subtitle: (
      <div>
        Al inhabilitarlo,{" "}
        <span style={{ fontFamily: "Ubuntu-Medium" }}>
          no podrás realizar operaciones.
        </span>
      </div>
    ),
    closeLabel: "CANCELAR",
    confirmLabel: "INHABILITAR",
    handleClose: () => this.setState({ modalOpen: false }),
    handleConfirm: this.handleToggle
  };

  enableProductModal = {
    title: "¿Quieres habilitar el producto ?",
    subtitle: (
      <div>
        Al habilitarlo podrás{" "}
        <span style={{ fontFamily: "Ubuntu-Medium" }}>
          realizar operaciones.
        </span>
      </div>
    ),
    closeLabel: "CANCELAR",
    confirmLabel: "HABILITAR",
    handleClose: () => this.setState({ modalOpen: false }),
    handleConfirm: this.handleToggle
  };

  saveProductModal = {
    title: "¿Quieres guardar la edición del producto antes de salir?",
    subtitle: "Si no lo guardas, se perderán los cambios.",
    closeLabel: "NO GUARDAR",
    confirmLabel: "GUARDAR",
    handleClose: this.goBack,
    handleConfirm: this.handleSubmit
  };

  render() {
    const { changed, errors } = this.state;
    const hasErrors =
      Object.values(errors).filter(error => error != null).length > 0;
    let content = (
      <div className="product-details-content">
        <div className="product-details-col product-details-col-description">
          <div className="product-details-description">
            <FormEditProduct
              product={this.state.product}
              stock={this.state.product?.stock || 0}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              selectCategory={this.selectCategory.bind(this)}
              errors={errors}
            ></FormEditProduct>
          </div>
        </div>

        <div className="product-details-col product-details-col-image-stock">
          <FormImageProduct
            product={this.state.product}
            handleChange={this.handleChangeImage}
            handleDelete={this.handleDeleteImage}
            error={this.state.errors}
            onHandleStarClick={this.handleStarClick}
            selectedStarIndex={this.state.selectedStarIndex}
          ></FormImageProduct>

          <FormDisableProduct
            product={this.state.product}
            handleChange={this.handleToggleModal}
          ></FormDisableProduct>
        </div>
        {isMobile && (
          <div className="product-details-button">
            <div className="product-details-button-inner">
              <RoundedButton
                legend="Guardar"
                type="submit"
                handleClick={this.handleSubmit}
                state={changed && !hasErrors ? "enabled" : "disabled"}
              ></RoundedButton>
            </div>
          </div>
        )}
        <TwoOptionModal
          open={this.state.modalOpen}
          {...this.state.modalInfo}
        ></TwoOptionModal>
      </div>
    );

    if (!this.state.product) content = null;

    return (
      <div className="product-details-container">
        <AdminLayout
          headerTitle={"Detalle de producto"}
          headerOptions={this.headerOptions}
          content={content}
          goBackFunc={this.goBackModal}
          headerButtonLabel={"Guardar"}
          navHidden={true}
          headerButtonHandler={this.handleSubmit}
          headerButtonState={changed && !hasErrors ? "enabled" : "disabled"}
        ></AdminLayout>
      </div>
    );
  }
}

export default ProductDetailsView;
