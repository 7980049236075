import React, { useEffect, useState } from "react";
import { MenuItem, Checkbox } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView, TreeItem } from "@mui/x-tree-view";

const TreeViewSearch = ({ data, setListId, inputText, groupsList, listId }) => {
  const [expand, setExpand] = useState([]);
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (groupsList) {
      if (inputText) {
        setExpand(groupsList);
      } else {
        setExpand([]);
      }
    }
  }, [inputText, groupsList]);

  useEffect(() => {
    if (inputText) {
      const lowercasedFilter = inputText.toLowerCase();
      const filtered = data.filter((item) => {
        return filterTree(item, lowercasedFilter);
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [inputText, data]);

  const filterTree = (node, filter) => {
    if (node.label.toLowerCase().includes(filter)) {
      return true;
    }
    if (node.children) {
      return node.children.some((child) => filterTree(child, filter));
    }
    return false;
  };

  const handleToggle = (node, checked) => {
    let newListId = [...listId];
    newListId = selectNodes(node, checked, newListId);
    setListId(newListId);
  };

  const renderTree = (nodes) => {
    return nodes.map((node) => {
      const childFits = node.children ? renderTree(node.children) : null;
      return node.label ? (
        <TreeItem
          key={node.id}
          nodeId={node.id}
          label={
            <MenuItem>
              <Checkbox
                onChange={(event) => {
                  handleToggle(node, event.target.checked);
                }}
                checked={listId ? listId.indexOf(node.id) > -1 : false}
              />
              {node.label}
            </MenuItem>
          }
        >
          {childFits}
        </TreeItem>
      ) : null;
    });
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expand}
      onNodeToggle={(event, nodeIds) => {
        setExpand(nodeIds);
      }}
    >
      {renderTree(filteredData)}
    </TreeView>
  );
};

const selectNodes = (node, checked, array) => {
  if (array) {
    if (!checked) {
      array = array.filter((v) => v !== node.id);
    } else {
      array.push(node.id);
    }
  }
  if (Array.isArray(node.children)) {
    node.children.forEach((childNode) => {
      array = selectNodes(childNode, checked, array);
    });
  }
  return array;
};

export default TreeViewSearch;