import { Button } from "@material-ui/core";
import { BorderColor, Margin, Padding } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RetailService } from "services/RetailService";
import style from "./ProductColoursAndSize.module.scss"

const ProductColoursAndSize = ({ retail, setSelectedProduct }) => {

    const [listRetails, setListRetails] = useState([])

    useEffect(() => {
        if (retail) {
            RetailService.getRetailsByBase(retail.sku_base).then((retails) => {
                console.log(retails);
                setListRetails(retails);
            })
        }
    }, [retail])

    const order = ["XXS", "XS", "S", "M", "L", "XL", "XXL"];

    const getCircleStyle = (colour) => {
        return {
            width: `50px`,
            height: `50px`,
            backgroundColor: colour,
            borderRadius: "50%",
            border: "2px solid #000000",
            display: "inline-block",
            margin: "5px"
        }
    }

    const setSelectedColour = (ret) => {
        setSelectedProduct(ret.sku)
    }

    const circleSelected = {
        width: `10px`,
        height: `10px`,
        backgroundColor: "#000000",
        borderRadius: "50%",
        display: "inline-block",
        marginTop: "50px"
    }

    const setSizeSelected = (ret) => {
        let selectedColour = retail.colour;
        let newSizeSelected = ret.size;
        const filteredProd = listRetails
            .filter(item => item.colour === selectedColour && item.size === newSizeSelected)
            .map(item => item.sku);
        setSelectedProduct(filteredProd[0])
    }

    const getSizeStyle = (ret) => {
        var selected = ret.size == retail.size;
        const filteredSizes = listRetails
            .filter(item => item.colour === retail.colour)
            .map(item => item.size);

        var sizeAvaible = filteredSizes.includes(ret.size);

        return {
            width: `50px`,
            height: `50px`,
            border: selected ? "3px solid #000000" : "2px solid rgb(138, 138, 138)",
            display: "inline-block",
            margin: "5px",
            background: selected || sizeAvaible ? "none" : `linear-gradient(45deg, rgb(233 73 73) 33px, transparent 2px) 14% 60% / 100% 100%`,
            pointerEvents: sizeAvaible ? "auto" : "none",
            opacity: sizeAvaible ? "1" : "0.5",
        }
    }

    return (
        <div>
            <div className={style["div-colours"]}>
                {[...listRetails].filter((item, index, self) =>
                    index === self.findIndex(obj => obj.colour === item.colour)
                ).map((ret) => {
                    return (
                        <div className={style["colours"]} >
                            <div>
                                <ButtonBase style={getCircleStyle(ret.colour)} onClick={() => setSelectedColour(ret)}>
                                    {ret.colour == retail.colour ?
                                        (<div style={circleSelected}></div>) : undefined}
                                </ButtonBase>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className={style["div-sizes"]}>
                {[...listRetails].filter((item, index, self) =>
                    index === self.findIndex(obj => obj.size === item.size)
                ).sort((a, b) => order.indexOf(a.size) - order.indexOf(b.size)).map((ret) => {
                    return (
                        <div>
                            <ButtonBase style={getSizeStyle(ret)} onClick={() => setSizeSelected(ret)}>
                                <div style={ret.id === retail.id ? { fontWeight: "bold" } : {}}>
                                    {ret.size}
                                </div>
                            </ButtonBase>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default ProductColoursAndSize;