import React, { useEffect, useState } from "react";

import { DeviceService } from "services/DeviceService";
import { WSService } from "services/WSServices";

import DeviceSelector from "components/MainApp/organisms/DeviceSelector";

let globalChannel = null;

const DeviceSelectorWrapper = ({
  enabled = false,
  setEnabled,
  typeRead,
  refRead,
  associateRead = false,
  priorityRead = "RFID",
  receiveEvent,
  onDeviceError,
  maxDevicesSelected = 99,
  includeType,
  receiveDevicesSelected = () => {}
}) => {
  const [optionsDevices, setOptionsDevices] = useState([]);
  const [selectDevices, setSelectDevices] = useState([]);
  const [devices, setDevices] = useState([]);
  const [channel, setChannel] = useState(false);
  const [disableToggle, setDisableToggle] = useState(false);

  useEffect(() => {
    globalChannel = null;

    const ws = WSService.getInstance();
    ws.listen("message", receiveEvent);

    let deviceOptions = {
      no_page: 1,
      enabled: true
    };

    if (includeType) {
      deviceOptions.includeType = includeType;
    } else {
      deviceOptions.excludeType = "ANTENNA|PRINTER";
    }

    DeviceService.readersDevices(deviceOptions)
    .then(devices => {
      setOptionsDevices(
        devices.map(d => {
          return { id: d.id, value: d.name, type: d.device_type.type };
        })
      );

      const devs = ws.refreshDevices(devices.map(d => d.id));
      setDevices(devs);
      setSelectDevices(devs);
    });

    return () => {
      if (globalChannel) {
        closeRoom();
      }
      ws.removeListener("message");
    };
  }, []);

  useEffect(() => {
    if (enabled) {
      openRoom();
    } else {
      closeRoom();
    }
  }, [enabled]);

  useEffect(() => {
    globalChannel = channel;
  }, [channel]);

  useEffect(() => {
    openRoom();
  }, [devices]);

  const handleToggleChange = () => {
    if (channel) {
      setEnabled?.(false);
      closeRoom();
    } else {
      setEnabled?.(true);
      openRoom();
    }

  };

  const closeRoom = () => {
    if (!globalChannel) {
      return;
    }

    DeviceService.stopRead({ channel: channel || globalChannel })
      .then(_ => {
        const ws = WSService.getInstance();
        ws.leaveRoom(channel);
        setChannel(null);
      })
      .catch(_ => {});
  };

  const openRoom = () => {
    if (devices.length === 0 || !enabled || globalChannel) return;

    const data = {
      devices: devices.map(d => d.id),
      type: typeRead,
      ref: refRead,
      associate: associateRead,
      priority: priorityRead
    };

    receiveDevicesSelected(data);

    if (
      associateRead === true &&
      priorityRead === "BARCODE" &&
      devices.length !== 2
    ) {
      data["associate"] = false;
      data["priority"] = "RFID";
    }

    DeviceService.startRead(data)
      .then(response => {
        setChannel(response.channel);
        const ws = WSService.getInstance();
        var channels = response.channel?.split("-");
        channels?.forEach((ch) => {
          ws.joinRoom(ch);
        })
        if(channels?.length > 1){
          ws.setRoom(response.channel);
        }
       })
      .catch(_ => {
        _.json().then(data => {
          setDisableToggle(true);
          setSelectDevices([]);
          if(onDeviceError) onDeviceError(data.detail);
        });
      });

    const ws = WSService.getInstance();
    ws.setDevices(devices);
  };

  const changeDevices = (devices = []) => {
    closeRoom();
    setChannel(null);
    setDevices(devices);
    setDisableToggle(devices.length <= 0);
  };

  return (
    <DeviceSelector
      devices={optionsDevices}
      selected={selectDevices}
      handleClose={changeDevices}
      handleToggleChange={handleToggleChange}
      disableToggle={disableToggle}
      checked={channel}
      maxDevicesSelected={maxDevicesSelected}
    />
  );
};

export default DeviceSelectorWrapper;
