import style from "./SuccessViewArc.module.scss";
import CheckCircleIcon from "assets/images/rimac-check-circle-filled.svg";

export const SuccessViewArc = ({ numberOrders }) => {
  return (
    <div className={style["success-view-arc"]}>
      <img src={CheckCircleIcon} alt="Icono de éxito" />
      <h3>Las órdenes se crearon de manera exitosa. </h3>
      {numberOrders && (
        <>
          <p>Nº de Orden</p>
          <ul>
            {numberOrders.map(order => (
              <li>{order}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};
