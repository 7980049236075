class Category
{
	id = null;
    name = null;
    description = null;
    enabled = null;
    cant_items = null;

    constructor(obj) {    
	    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    	for (var prop in obj) this[prop] = obj[prop];
  	}
}

class Brand
{
    id = null;
    name = null;
    description = null;
    enabled = null;

    constructor(obj) {    
        // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
        for (var prop in obj) this[prop] = obj[prop];
    }
}

class Retail
{
	id = null;
    sku = null;
    skuBase = null;
    colour = null;
    size = null;

    constructor(obj) {    
	    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    	for (var prop in obj) this[prop] = obj[prop];
  	}
}

export {
    Category,
    Brand,
    Retail
}
