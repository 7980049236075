import Environment from "../environment";
import Api from './ApiService';

import { Retail } from "../models/Attributes";


export const RetailService = {
	retails: () => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/retails/`, 'GET')
                .then(rta => {
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
	},

    getRetailsByBase: (skuBase) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/retail/retail_by_base/`, 'GET', { "sku": skuBase }
                ).then(data => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },
}
