import {
  ListItem, ListItemText, Box,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Avatar,
  CardHeader,
  IconButton,
  CardMedia,
  ListItemIcon,
  Collapse,
  List,
  Divider,
} from '@material-ui/core';
import Zoom from '@mui/material/Zoom';

import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import { Grid, ListItemButton } from '@mui/material';
import style from "./ListItemProductTag.module.scss";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';


const ListItemProductTag = ({ tag }) => {

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (<>
    <ListItemButton onClick={handleClick} className={style["button-list"]}>
      <img src={tag.product?.image} className={style["image"]} />
      <div className={style["text"]}>{tag.product?.name}</div>
      <div className={style["text-end"]}>{tag.sku}</div>
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={open} timeout="auto" unmountOnExit>

      {tag.epc &&
        <ListItemButton sx={{ pl: 3 }}>
          <ListItemText  primary={"EPC: " + tag.epc} />
        </ListItemButton>
      }
      {tag.epcs &&
        tag.epcs.map((tag, index) => {
          return (
            <>
              <Grid index={index} container spacing={1}>
                <Grid item xs={12}   >
                  <ListItemText primary={tag} sx={{ marginLeft: '15px' }} />
                </Grid>
              </Grid>
              <Divider />
            </>
          )
        })
      }
    </Collapse>
  </>

  );
}

export default ListItemProductTag;