import React, {useState, useEffect} from "react";
import {useHistory} from 'react-router-dom'

import i18n from "i18n";
import { PublicService } from 'services/PublicService.jsx';

import { steps } from './data/steps';
import Loader from "components/MainApp/atoms/Loader";
import AdminLayout from 'components/MainApp/layouts/DesktopLayout';
import StepProgress from 'components/MainApp/atoms/Wizard/StepProgress';
import StepOne from './Wizard/Step1';
import StepTwo from './Wizard/Step2';
import StepThree from  './Wizard/Step3';
import AddBusinessNodeSuccess from '../AddBusinessNodeSuccess';

import './styles.scss';
import { BusinessNodeService } from "../../../../services/BusinessNodeService";
import { AuthService } from "services/AuthService";
import { checkMultipleIsNotNullOrWhitespace } from "helpers/utils/utils_general";
const initialBusinessNode = {
    name: '',
    country: '',
    region: '',
    state: '',
    locality: '',
    address: '',
    owner: '',
    phone: '',
    email: '',
    measure_height: null,
    measure_width: null,
    measure_depth: null,
    capacity: null,
    number: 36,
	  type: "DEPOSIT",
}

const initialError = {
    success: true,
    message: '',
    errors: []
}

const AddBusinessNode = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState('0');
    const [sentSuccess, setSentSuccess] = useState(false);
    const [businessNode, setBusinessNode] = useState(initialBusinessNode);
    const [countries, setcountries] = useState([]);
    const [regions, setregions] = useState([]);
    const [provinces, setprovinces] = useState([]);
    const [cities, setcities] = useState([]);
    const headerOptions = [];
    const [error, seterror] = useState(initialError);

    useEffect(() => {
        let countriesAux = []
        PublicService.countries().then((response) => {
            countriesAux = response
            setcountries(response)
          });

        if(props.match.params.key){
            const id = props.match.params.key
            setLoading(true)

            BusinessNodeService.businessNode(id).then(node => {
                let regions;
                let states;
                let localities;

                let regionsPromises = PublicService.regions(node.country)
                let statesPromises = PublicService.states(node.country, node.region)
                let citiesPromises = PublicService.cities(node.country, node.region, node.state)

                Promise.all([regionsPromises, statesPromises, citiesPromises]).then(responses => {
                    regions = responses[0]
                    setregions(regions)

                    states = responses[1]
                    setprovinces(states)

                    localities = responses[2]
                    setcities(localities)

                    node.country = countriesAux.find(c => c.value === node.country);
                    node.locality = localities.find(c => c.value === node.locality);
                    node.region = regions.find(c => c.value === node.region);
                    node.state = states.find(c => c.value === node.state);

                    setBusinessNode(node)
                    setLoading(false)
                })

            })
        }


    }, []);


    const showError = (message, errors = {}) => {
        message = message.replace('""', '')
        seterror({
            success: false,
            message: message,
            errors: errors
        })

        if(['email', 'phone', 'owner'].includes(Object.keys(errors)[0])){
            setActiveStep('1')
        }

        if(['name', 'country', 'state', 'locality', 'region', 'address'].includes(Object.keys(errors)[0])){
            setActiveStep('0')
        }
    }

    const dealWithError = (response, codeMsgError = "generic.error", callback=null) => {
        if ([400, 401, 409].includes(response.status)) {
            response.json().then((data) => {
                if (Array.isArray(data)) {
                    showError(data.join(", "));
                } else if ('detail' in data) {
                    showError(data['detail']);
                } else if ('message' in data && 'errors' in data) {
                    showError(data['message'], data['errors']);
                } else {
                    showError(codeMsgError, data);
                }
                if (callback) callback();
            });
        } else {
            showError(codeMsgError);
            if (callback) callback();
        }
      }


    const handleFinish = (e) => {
        e.preventDefault();

        setLoading(true)
        let businessNodeAux = {...businessNode}
        businessNodeAux.country = businessNodeAux.country.value;
        businessNodeAux.region = businessNodeAux.region?.value || '';
        businessNodeAux.state = businessNodeAux.state?.value || '';
        businessNodeAux.locality = businessNodeAux.locality?.value || '';
        businessNodeAux.measure_width = businessNodeAux.measure_width || null;
        businessNodeAux.measure_depth = businessNodeAux.measure_depth || null;
        businessNodeAux.measure_height = businessNodeAux.measure_height || null;        businessNodeAux.capacity = businessNodeAux.capacity || null;

        if(businessNodeAux.code){
            BusinessNodeService.updateBusinessNode(businessNodeAux.code, businessNodeAux).then(node => {
                setLoading(false)
                history.push(`/admin/businessnodes`);
                AuthService.loadUser(true);
            }).catch(error => {
                console.log(error)
                dealWithError(error)
                setLoading(false)
            })
        }else{
            BusinessNodeService.createBusinessNode(businessNodeAux).then( node =>{
                setSentSuccess(true)
                AuthService.loadUser(true);
                setBusinessNode(node)
                setLoading(false)
            }).catch(error => {
                console.log(error)
                dealWithError(error)
                setLoading(false)
            })
        }
    }

    const goToSetupLocations = () => {
        history.push(`/admin/businessnodes`)
    }

    const handleGoBack = () => {
        history.push(`/admin/businessnodes`)
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let businessNodeAux = {...businessNode}
        businessNodeAux[name] = value;

        setBusinessNode(businessNodeAux)
    }

    const handleSelect = (selectedOption, type) => {
        let businessNodeAux = {...businessNode}
        switch (type) {
            case 'country':
                businessNodeAux.country = selectedOption;
                businessNodeAux.region = ''
                businessNodeAux.state = ''
                businessNodeAux.locality = ''
                PublicService.regions(businessNodeAux.country.value).then(response => {
                    setregions(response)
                });
                setBusinessNode(businessNodeAux);
                break;
            case 'region':
                businessNodeAux.region = selectedOption;
                businessNodeAux.state = ''
                businessNodeAux.locality = ''
                PublicService.states(businessNodeAux.country.value, businessNodeAux.region.value).then(response => {
                    setprovinces(response)
                });
                setBusinessNode(businessNodeAux);
                break;
            case 'state':
                businessNodeAux.state = selectedOption;
                businessNodeAux.locality = ''
                PublicService.cities(businessNodeAux.country.value, businessNodeAux.region.value, businessNodeAux.state.value).then(response => {
                    setcities(response)
                });
                setBusinessNode(businessNodeAux);
                break;
            case 'city':
                businessNodeAux.locality = selectedOption;
                setBusinessNode(businessNodeAux);
                break;

                default:
                    break;
        }
    }

    const validateStep0 = () => {
        if(businessNode){
            const isSpain = businessNode.country?.value === 'ES';

            return (
              businessNode.name !== '' &&
              businessNode.country !== '' &&
              businessNode.currency !== '' &&
              (!isSpain || (isSpain && businessNode.region?.value?.trim().length > 0)) &&
              (!isSpain || (isSpain && businessNode.state?.value?.trim().length > 0)) &&
              (!isSpain || (isSpain && businessNode.locality?.value?.trim().length > 0)) &&
              businessNode.address?.trim().length > 0
            );
        }
         else{
             return false
         }
    }

    const validateStep1 = () => {
      return (
        businessNode &&
        businessNode.owner?.trim().length > 0 &&
        businessNode?.phone.trim().length > 0 &&
        businessNode?.email.trim().length > 0
      );
    };

    const goToStep = (step) => {
        setActiveStep(step)
    }

    const handleContinue = (e, step) => {
      e.preventDefault();
      if (
        (step === "1" && validateStep0()) ||
        (step === "2" && validateStep1())
      ) {
        setActiveStep(step);
      }
    };

    const headerTitle = sentSuccess ? 'Sucursal creada' : props.match.params?.key ? 'Editando sucursal' : 'Nueva sucursal';

    const step0 = (
      <StepOne
        handleContinue={(e) => handleContinue(e, '1')}
        businessnode={businessNode}
        handleSelect={handleSelect}
        handleChange={handleChange}
        countries={countries}
        regions={regions}
        provinces={provinces}
        cities={cities}
        enableContinue={validateStep0()}
        error={error}
      ></StepOne>
    );

    const step1 = (
      <StepTwo
        handleContinue={(e) => handleContinue(e, '2')}
        goBack={() => goToStep("0")}
        handleChange={handleChange}
        enableContinue={validateStep1()}
        businessnode={businessNode}
        error={error.errors}
      ></StepTwo>
    );

    const step2 = <StepThree handleFinish={handleFinish} goBack={() => goToStep('1')} businessnode={businessNode} handleChange={handleChange} error={error.errors}></StepThree>

    // const stepList = [step0, step1, step2];
    const stepList = {
        '0': step0,
        '1': step1,
        '2': step2
    }

    const addSuccess = <div className={`add-business-node-success`}><AddBusinessNodeSuccess id={businessNode && businessNode.code} name={businessNode && businessNode.name} boldButtonFunc={goToSetupLocations}/></div>

    const content = <div className={`add-business-node-content`}>
        {!sentSuccess && <div className={`add-business-node-wrapper`}>
            <StepProgress steps={steps} activeStep={activeStep}/>
            <div className={`add-business-node-step-wrapper`}>
                {stepList[activeStep]}
            </div>
        </div>}
    </div>

    return(
        <div className="add-business-node-container">
        <AdminLayout
          headerTitle={i18n.t(headerTitle)}
          headerOptions={headerOptions}
          content={sentSuccess ? addSuccess : content}
          navHidden={true}
          goBackFunc={handleGoBack}
        ></AdminLayout>
        {loading && <Loader />}
      </div>
    )
}

export default AddBusinessNode;