import React, { useState } from "react";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import Icon from "components/MainApp/atoms/Icon/icon.jsx";

import "./styles.scss";

const AddLocationForm = ({
  location,
  handleChange,
  error
}) => {
  return (
    <div className="add-location-form-container">
      <div className="add-location-form-content">
        <div
          className="add-location-form-field"
          data-automation-id="input-name"
        >
          <MainInput
            label={"Nombre"}
            required={true}
            id={"location-name"}
            name="name"
            value={location ? location.name : ""}
            handleChange={handleChange}
            maxLength={30}
            error={error.name && error.name}
          />
        </div>
        <div className="add-location-form-field" data-automation-id="input-id">
          <MainInput
            label={"ID"}
            required={true}
            id={"location-id"}
            name="code"
            value={location ? location.code : ""}
            handleChange={handleChange}
            maxLength={30}
            error={error.code && error.code}
          />
        </div>
        <div
          className="add-location-form-field"
          data-automation-id="input-measure_depth"
        >
          <MainInput
            label={"Profundidad(mts)"}
            required={true}
            type="number"
            id={"location-measure_depth"}
            name="measure_depth"
            value={location ? location.measure_depth : ""}
            handleChange={handleChange}
            maxLength={15}
            onlyNumbers="true"
            positiveNumbers="true"
            error={error.measure_depth && error.measure_depth}
          />
        </div>
        <div
          className="add-location-form-field"
          data-automation-id="input-measure_width"
        >
          <MainInput
            label={"Ancho(mts)"}
            required={true}
            type="number"
            id={"location-measure_width"}
            name="measure_width"
            value={location ? location.measure_width : ""}
            handleChange={handleChange}
            maxLength={15}
            onlyNumbers="true"
            positiveNumbers="true"
            error={error.measure_width && error.measure_width}
          />
        </div>
        <div
          className="add-location-form-field"
          data-automation-id="input-measure_height"
        >
          <MainInput
            label={"Alto(mts)"}
            required={true}
            type="number"
            id={"location-measure_height"}
            name="measure_height"
            value={location ? location.measure_height : ""}
            handleChange={handleChange}
            maxLength={15}
            onlyNumbers="true"
            positiveNumbers="true"
            error={error.measure_height && error.measure_height}
          />
        </div>
        <div
          className="add-location-form-field"
          data-automation-id="input-capacity"
        >
          <MainInput
            label={"Capacidad"}
            required={false}
            id={"location-capacity"}
            name="capacity"
            value={location ? location.capacity : ""}
            handleChange={handleChange}
            maxLength={30}
            onlyNumbers="true"
            positiveNumbers="true"
            error={error.capacity && error.capacity}
          />
        </div>
      </div>
    </div>
  );
};

export default AddLocationForm;
