import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import { DeviceService } from "services/DeviceService";

import useRequest from "hooks/useRequest";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import {
  Container,
  Box,
  Typography,
  FormControlLabel,
  Divider,
  Button,
  Grid,
  Switch,
  ListSubheader,
  List,
  ListItemText,
  Paper,
  IconButton,
  Checkbox
} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import { WSService } from "services/WSServices";
import "./styles.scss";
import { ProductService } from "services/ProductService";
import { styled } from "@mui/material/styles";
import ListItemProductArcTag from "shared/components/list-item-product-arc-tag/ListItemProductArcTag";
import DeviceSelector from "components/Functionals/DeviceSelector";
import { AuthService } from "services/AuthService";
import PopupLocations from "shared/components/popup-locations/PopupLocations";
import { TagService } from "services/TagService";
import { EventRepository } from "helpers/EventRepository";
import { OrderService } from "services/OrderService";
import { StockService } from "services/StockService";

import tagIcon from "assets/images/tag-icon.svg";
import { EmptyArcTags } from "shared/components/empty-arc-tags/EmptyArcTags";
import { ActionButtonsArc } from "shared/components/action-buttons-arc/ActionButtonsArc";
import { SuccessViewArc } from "shared/components/success-view-arc/SuccessViewArc";

let globalChannel = null;
let arrayTags = [];
var timeOutRemoveAlert = null;

const ArcActivityView = props => {
  const history = useHistory();
  const [channel, setChannel] = useState(false);
  const [arrayProducts, setArrayProducts] = useState([]);
  const [myTime, setMyTime] = useState(new Date());
  const [arrayTagsSaving, setArrayTagsSaving] = useState([]);
  const [enabledButton, setEnabledButton] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  const [arrayEpcsReads, setArrayEpcsReads] = useState([]);
  const [totalEpcsReads, setTotalEpcsReads] = useState(0);
  const [uniqueTotalEpcsReads, setUniqueTotalEpcsReads] = useState(0);
  const [tagsOriginDestination, setTagsOriginDestination] = useState([]);
  const [deviceSerial, setDeviceSerial] = useState("");
  const [enableDeviceSelector, setEnableDeviceSelector] = useState(true);
  const [showView, setShowView] = useState({ initial: true, confirmation: false, success: false });

  const [groupedProducts, setGroupedProducts] = useState([]);
  const [tabActive, setTabActive] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [numberOrders, setNumberOrders] = useState(null);

  const FIRST_DESTINATION = groupedProducts[0]?.name || null;
  const SECOND_DESTINATION = groupedProducts[1]?.name || null;

  const FIRST_DESTINATION_EPC_COUNT = groupedProducts[0]?.epcs.length;
  const SECOND_DESTINATION_EPC_COUNT = groupedProducts[1]?.epcs.length;

  const showInitialView = () => setShowView({ initial: true, confirmation: false, success: false })
  const showConfirmationView = () => setShowView({ initial: false, confirmation: true, success: false })
  const showSuccessView = () => setShowView({ initial: false, confirmation: false, success: true })

  // useEffect(() => {
  //   var timerID = setInterval(() => {
  //     tick();
  //     //console.log(arrayTags);
  //     //console.log(arrayTagsSaving);
  //   }, 200);

  //   return () => clearInterval(timerID);
  // }, []);

  const handleChangeTab = newTab => setTabActive(newTab);

  useEffect(() => {
    const totalEpcs = arrayTagsSaving.reduce(
      (total, tag) => total + tag.epcs.length,
      0
    );
    setUniqueTotalEpcsReads(totalEpcs);
    if (totalEpcs > 0 && deviceSerial) {
      arrayEpcsReads?.forEach(epc => {
        getTagOriginDestination(epc, deviceSerial);
      });
    }
  }, [arrayTagsSaving]);

  const getTagOriginDestination = epc => {
    if (deviceSerial) {
      // comprobamos que no esté en tagsOriginDestination
      const existTag = tagsOriginDestination.find(t => t.epc === epc);
      if (!existTag) {
        // console.log("-3333 buscamos y agregamos tag", epc);
        StockService.tag_origin_destination(epc, deviceSerial)
          .then(response => {
            setTagsOriginDestination(prevTags => [...prevTags, response]);
          })
          .catch(error => {
            console.error(error.message);
          });
      }
    }
  };

  const tick = () => {
    var date = new Date();
    setMyTime(date);
  };

  const labelQueue = { inputProps: { "aria-label": "Queue" } };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "block",
    height: "300%",
    marginLeft: "25vw",
    marginTop: "25vh",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626"
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959"
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343"
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c"
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff"
    }
  }));

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();

  const receiveEvent = useCallback(payload => {
    if(showView.confirmation) return;
    
    const ws = WSService.getInstance();
    // console.log("Message from socket");
    // console.log(payload);

    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {
      setDeviceSerial(payload.room);

      let epc = payload.params["epc"];
      // console.log("----> epc: ", epc);
      // console.log(epc)
      let existEPC = false;
      setArrayEpcsReads(currentArrayEpcsReads => {
        if (currentArrayEpcsReads.length > 0) {
          existEPC = currentArrayEpcsReads.some(epcRead => epcRead == epc);
          if (!existEPC) {
            currentArrayEpcsReads.push(epc);
            // getTagOriginDestination(epc, payload.room);
          }
        } else {
          currentArrayEpcsReads.push(epc);
          getTagOriginDestination(epc, payload.room);
        }
        return currentArrayEpcsReads;
      });

      if (!existEPC) {
        TagService.itemFromTag(epc)
          .then(item => {
            const tag = {
              epcs: [epc],
              sku: item.sku,
              product: item
            };
            const tagFound = arrayTags.find(t => t.sku === item.sku);
            if (tagFound) {
              tagFound.epcs.push(epc);
            } else {
              arrayTags.push(tag);
            }

            setTotalEpcsReads(current => {
              return parseInt(current) + 1;
            });

            updateListTags();
          })
          .catch(error => {
            console.error("Tag not found:", error);
          });
      }
    }
  }, []);

  const updateListTags = () => {
    let uniqueTags = arrayTags.filter(
      (value, index, self) => index === self.findIndex(t => t.sku === value.sku)
    );

    setArrayTagsSaving([...uniqueTags]);
    //setLastUpdate(new Date())
  };

  useEffect(() => {
    globalChannel = null;

    const ws = WSService.getInstance();
    ws.listen("message", receiveEvent);

    return () => {
      if (globalChannel) {
        //closeRoom();
      }
      ws.removeListener("message");
    };
  }, []);

  useEffect(() => {
    globalChannel = channel;
  }, [channel]);

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  var removeByAttr = function(arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };

  const cleanScrenn = () => {
    setIsLoading(true);

    arrayTags = [];
    setArrayTagsSaving([]);
    setArrayEpcsReads([]);
    setTotalEpcsReads(0);
    setTagsOriginDestination([]);
    setTabActive(null);
    
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const handleBackConfirmation = () => {
    setIsLoading(true);
    showInitialView();
    setEnableDeviceSelector(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }

  const handleApply = () => {
    // borramos duplicados de tagsOriginDestination con el mismo epc
    let tagsOriginDestinationUnique = tagsOriginDestination.filter((value, index, self) =>
      index === self.findIndex((t) => ( t.epc === value.epc))
    )

    // obtenemos el location del primer elemento de tagsOriginDestination
    const location = tagsOriginDestinationUnique[0]?.destination;

    if (!location) {
      EventRepository.notificationSend({
        label: "No hay una ubicación destino",
        type: "error"
      });
      return;
    }

    if (arrayTagsSaving.length == 0) {
      EventRepository.notificationSend({
        label: "No hay tags para ubicar",
        type: "error"
      });
      // setLocationOpen(false)
      return;
    }
    let listTags = [];
    arrayTagsSaving.forEach(tag => {
      listTags.push(...tag.epcs);
    });
    //  console.log(listTags)
    let params = {
      rfid: listTags,
      // "location": selectedLocation.id,
      "location": location,
      "node": AuthService.getCurrentNodeCode(),
      "tagsOriginDestination": tagsOriginDestinationUnique,
    }
    beforeSubmit();
    OrderService.relocationSimplifedMove(params)
      .then(response => {
        EventRepository.notificationSend({
          label: "Tags ubicados correctamente",
          type: "success"
        });
        afterSubmit();
        cleanScrenn();
        setLocationOpen(false);
        setEnableDeviceSelector(false);
        showSuccessView();
        setNumberOrders([...response.in_orders])
      })
      .catch(error => {
        console.error(error.message);
        EventRepository.notificationSend({
          label: "Error al ubicar los tags",
          type: "error"
        });
        afterSubmit();
      });
  };

  const handleSubmit = () => {
    if(showView.initial) {
      setIsLoading(true);
      showConfirmationView();
      setEnableDeviceSelector(false);

      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }

    if(showView.confirmation) {
      handleApply();
    } 
    
    if(showView.success) {
      showInitialView();
    }
  }

  const handleGoBack = () => {
    history.push(`/admin`);
  };

  useEffect(() => {
    const grouped = {};
  
    // Agrupar los productos por destination
    tagsOriginDestination.forEach(epcItem => {
      const { destination, destination_path_name, epc } = epcItem;
  
      // Si el destination no está en el objeto agrupado, lo inicializamos
      if (!grouped[destination]) {
        grouped[destination] = {
          name: destination_path_name,
          items: [],
          epcs: []
        };
      }
  
      // Verificar si el epc ya está en el arreglo sino se agrega
      const isEpcInGroup = grouped[destination].epcs.some(e => e === epc);
      if (!isEpcInGroup) {
        grouped[destination].epcs.push(epc);
      }
  
      // Buscar el producto que contiene este EPC
      arrayTagsSaving.forEach(product => {
        if (product.epcs.includes(epc)) {
          const isProductInGroup = grouped[destination].items.some(
            item => item.product.id === product.product.id
          );
  
          // Filtrar los epcs de product que están en grouped[destination].epcs
          const filteredEpcs = product.epcs.filter(item => grouped[destination].epcs.includes(item));
  
          if (!isProductInGroup) {
            grouped[destination].items.push({
              product: product.product,
              epcs: filteredEpcs
            });
          } else {
            // Si el producto ya está en el grupo, actualizamos su lista de epcs
            grouped[destination].items = grouped[destination].items.map(item => {
              if (item.product.id === product.product.id) {
                return {
                  ...item,
                  epcs: [...new Set([...item.epcs, ...filteredEpcs])]
                };
              }
              return item;
            });
          }
        }
      });
    });
  
    setGroupedProducts(Object.values(grouped));
  }, [tagsOriginDestination, arrayTagsSaving]);
  

  useEffect(() => {
    if (groupedProducts.length > 0 && !tabActive) {
      setTabActive(FIRST_DESTINATION);
    }
  }, [groupedProducts]);

  const content = (
    <Container className="ArcActivityView__content">
      <div className="header" style={{ display: showView.initial ? "block" : "none" }}>
        <DeviceSelector
          enabled={enableDeviceSelector}
          setEnabled={(enable) => setEnableDeviceSelector(enable)}
          typeRead={"READ"}
          refRead={""}
          receiveEvent={receiveEvent}
          includeType={"FLOW"}
          maxDevicesSelected={1}
        />
      </div>
      <Grid
        container
        className={`grid-item-view-arc ${
          showView.confirmation ? "grid-item-view-arc__confirmation-view" : ""
        }`}
      >
        <Grid item xs={12}>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} className="grid-column-arc">
              {!showView.success &&
                <div className="ArcActivityView__tags-reads">
                  <article>
                    <div>
                      <picture>
                        <img src={tagIcon} alt="tag icon" />
                      </picture>
                      <h4>Tags leídos</h4>
                    </div>
                    <span>{totalEpcsReads}</span>
                  </article>
                </div>
              }

              {showView.initial &&
                <>
                  {FIRST_DESTINATION &&
                    <nav className="ArcActivityView__nav-tab">
                      <button
                        className={tabActive === FIRST_DESTINATION ? "active" : ""}
                        onClick={() => handleChangeTab(FIRST_DESTINATION)}
                        disabled={!FIRST_DESTINATION}
                        >
                        Destino {FIRST_DESTINATION}
                        <span>{FIRST_DESTINATION_EPC_COUNT}</span>
                      </button>
                      <button
                        className={tabActive === SECOND_DESTINATION ? "active" : ""}
                        onClick={() => handleChangeTab(SECOND_DESTINATION)}
                        disabled={!SECOND_DESTINATION}
                      >
                        Destino {SECOND_DESTINATION}
                        <span>{SECOND_DESTINATION_EPC_COUNT}</span>
                      </button>
                    </nav>
                  }

                  <List
                    component="div"
                    aria-labelledby="nested-list-subheader"
                    className="ArcActivityView__list"
                  >
                    {groupedProducts.length > 0 ?
                      groupedProducts.find(group => group.name === tabActive)?.items.map((tag, index) => {
                        const epcsOriginDestination = tag.epcs.map(epc => {
                          return tagsOriginDestination?.find(t => t.epc === epc);
                        });
                        
                        return (
                          <ListItemProductArcTag
                            key={index}
                            tag={tag}
                            epcsOriginDestination={epcsOriginDestination}
                            isCollapsable
                          />
                        );
                      }) : <EmptyArcTags /> }
                  </List>
                </>
              }

              {showView.confirmation &&
                groupedProducts.map((product, productIndex) => (
                  <div key={productIndex} className="ArcActivityView__confirmation-view">
                    <div>
                      <h4>Destino {product.name}</h4>
                      <span>{product.epcs.length}</span>
                    </div>
                    <div>
                      {product.items.map((tag, index) => {
                        const epcsOriginDestination = tag.epcs.map(epc => {
                          return tagsOriginDestination?.find(t => t.epc === epc);
                        });

                        return (
                          <ListItemProductArcTag
                            key={index}
                            tag={tag}
                            epcsOriginDestination={epcsOriginDestination}
                          />
                        );
                      })}
                    </div>
                  </div>
                ))
              }

              {showView.success && <SuccessViewArc numberOrders={numberOrders} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ActionButtonsArc
        cleanScrenn={cleanScrenn}
        handleBack={handleBackConfirmation}
        handleSubmit={handleSubmit}
        groupedProducts={groupedProducts}
        showView={showView}
      />
      {/* <PopupLocations handleConfirm={handleApply} open={locationOpen} handleClose={() => setLocationOpen(false)} /> */}
    </Container>
  );

  return (
    <div className="device-detail-container">
      <AdminLayout
        headerTitle={i18n.t("Arco")}
        headerOptions={[]}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      {(loading || isLoading) && <Loader />}
    </div>
  );
};

export default ArcActivityView;
