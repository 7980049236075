import { Collapse } from "@material-ui/core";
import style from "./ListItemProductArcTag.module.scss";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import default_image from "../../../assets/images/default_product.png";
import zoneIcon from "assets/images/zone-icon.svg";

const ListItemProductTag = ({ tag, epcsOriginDestination = [], isCollapsable = false }) => {
  const [open, setOpen] = useState(!isCollapsable);

  const handleClick = () => {
    if(isCollapsable) setOpen(!open);
  };

  return (
    <article index={tag.product?.sku} className={isCollapsable ? style["main-list-collapsable"] : style["main-list"]}>
      <div onClick={handleClick} className={style["button-list"]}>
        <div>
          <img
            src={tag.product?.image ? tag.product?.image : [default_image]}
            className={style["image"]}
          />
          <div className={style["detail"]}>
            <h4>{tag.product?.name}</h4>
            <p>SKU {tag.product?.sku}</p>
          </div>
        </div>
        <div>
          {isCollapsable && (open ? <ExpandLess /> : <ExpandMore />)}
          <p className={style["epc-count"]}>
            Tags <span>{tag.epcs.length}</span>
          </p>
        </div>
      </div>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <ul className={style["ListItemProductTag_list-epcs"]}>
          {tag?.epcs?.map(tag => {
             let origin = '';
             let destination = '';
             if(epcsOriginDestination.length > 0) {
               const epc = epcsOriginDestination?.find(epc => epc?.epc === tag);
               if(epc) {
                 origin = epc.origin_path_name || '-';
                 origin += origin?.origin_node ? ' (' + origin?.origin_node +')' : '';
                 destination = epc.destination_path_name || '';
               }
             }

            return (
            <li>
              <h3>{tag}</h3>
              <div>
                <span>
                  <img src={zoneIcon} alt="Icono de ubicación" />
                  <p>Origen</p>
                  <h5>{origin}</h5>
                </span>
                <span>
                  <img src={zoneIcon} alt="Icono de ubicación" />
                  <p>Destino</p>
                  <h5>{destination}</h5>
                </span>
              </div>
            </li>
          )})}
        </ul>
      </Collapse>
    </article>
  );
};

export default ListItemProductTag;
